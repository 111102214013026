import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 d-none d-md-block"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                How is a Crypto OTC exchange different from a normal exchange?
                </Accordion.Toggle>
                
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">In a normal crypto exchange users don't directly buy and sell cryptocurrencies from each other. They do it through an exchange. In an OTC crypto exchange your users can buy and sell directly from each other.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                  Is it very costly to build an OTC crypto exchange platform?
                </Accordion.Toggle>
                
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">It is not as costly as going the traditional route. We have most of the scripts ready and multiple platforms with us for implementing in the minimum possible time. We have developed excellent libraries through hard work.  Nonetheless, the cost to develop OTC Trading platform depends on the scale of your OTC cryptocurrency exchange.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Will people be able to sell all cryptocurrencies using my OTC cryptocurrency exchange?
                </Accordion.Toggle>
                
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Yes, they will be able to sell and buy all contemporary and major cryptocurrencies through your OTC Exchange.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                  Is KYC verification mandatory for OTC crypto exchange users?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">KYC verification is not mandatory although it is recommended. Complete anonymity is incorporated into the crypto OTC exchange interface to encourage direct transaction between the users.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                  How do I track all the transactions?
                </Accordion.Toggle>
                
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">All the crypto transactions are immutable and they are stored within multiple blockchains. The information will be immediately available to you through your dashboard.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                What security features will be available in the OTC Trading platform?
                </Accordion.Toggle>
                
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Some of the security features include escrow facility, decentralized ledgers, two factor authentication, hot/cold wallet automation and dedicated wallet implementation.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection