import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Core Features</span> of our</span> OTC Crypto Exchange Development</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  User Panel
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Powerful Admin Portal
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Integrated Crypto Wallet
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Live Price Ticker
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  KYC System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Multi-region Transactions
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  You decide the price
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Content Management System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Currency Settings
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Support Tickets
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  Referral System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Multiple Admin
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">User Panel
                        </h4>
                        <p className="pharagraph">We provide an user friendly panel with a simple and easy interface for users which makes them trade cryptocurrencies effortlessly. 
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="440px" height="282px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/user-panel.png" alt="Crypto Wallets image1" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Powerful Admin Portal </h4>
                        <p className="pharagraph">We provide a robust admin panel where you can monitor and manage all the users &
                          their actions overall or filter and view the individual records of each user.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="414px" height="280px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/powerful-admin-portal.png" alt="Powerful Admin Portal image1" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Integrated Crypto Wallet</h4>
                        <p className="pharagraph">Our OTC exchange software is a multi-purpose system which can also be used to send,
                          receive and store cryptocurrencies in a secured manner.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/otc/integrated-crypto-wallet-crypto-wallet.png" alt="Integrated Crypto Wallet image1" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Live Price Ticker
                        </h4>
                        <p className="pharagraph">Get the accurate exchange rates for all the coins and currencies that can help the users
                          to calculate the buy/sell amount easily.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/otc/live-price-ticker.png" alt="Live Price Ticker image1" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">KYC System</h4>
                        <p className="pharagraph">The KYC process is mandatory in our software which includes ID verification, Document
                          verification and face verification to make sure the platform is more legit.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="287px" height="231px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/kyc-verification.png" alt="KYC System image1" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi-region Transactions</h4>
                        <p className="pharagraph">Aside from having multiple cryptocurrencies & payment options, our OTC Exchange solution is capable of global transactions so the users can access it from anywhere in the world.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="392px" height="336px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/multi-region-transactions.png" alt="Multi-region Transactions image1" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">You decide the price</h4>
                        <p className="pharagraph">Set a margin by increasing or decreasing the market value and buy/sell the
                          cryptocurrencies at your own desired price. </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="505px" height="273px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/you-decide-the-price.png" alt="You decide the price image1" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Content Management System</h4>
                        <p className="pharagraph">Manage the entire site contents with all the formatting tools and options from the CMS
                          settings we provide in our exchange solutions.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="301px" height="296px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/content-management-system.png" alt="Content Management System image1" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Currency Settings</h4>
                        <p className="pharagraph">The admin can be able to manage and edit the withdrawal fee, buy/sell commission %, minimum deposit/withdrawal, minimum buy/sell amount etc…</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="278px" height="314x" src="https://coinsclone.mo.cloudinary.net/images/otc-new/currency-settings.png" alt="Currency Settings image1" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Support Tickets</h4>
                        <p className="pharagraph">Admin can view tickets submitted by all users and can update/respond to the tickets to solve user inquiries and issues.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="293px" height="267px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/support-tickets.png" alt="Support Tickets image1" />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Referral System</h4>
                        <p className="pharagraph">Users can invite their friends or other crypto users so that they can earn rewards or bonuses. It helps to grab more users on your crypto exchange platform.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="313px" height="240px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/referral-systerm.png" alt="Referral System image1" />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multiple Admin</h4>
                        <p className="pharagraph">The primary admin can create more sub-admins and grant them limited access. This helps in reducing the workload of the main admin and make it easy to set up a back office for your exchange.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img className='lazyload' width="231px" height="297px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/multiple-admin.png" alt="Multiple Admin image1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures