import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {


  render() {

    return (
      <div className='landing'>
      <section className="banner  casestudy">  
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/otc-new/bankto.webp"
                    alt="Bankto logo"
                    width={316}
                    className='mb-4'
                />
                <ul>
                    <li>Bankto, a popular Crypto payment gateway company decided to add an <b>OTC crypto exchange</b> to its wide range of services.</li>
                    <li>Our OTC exchange software helped Bankto build a bulk trading platform where Crypto can be bought for the best rates.</li>
                    <li>Soon, the platform reached 8k followers in 3 months and showed massive improvements among its competitors.</li>
                    <li>Bankto now stands as a top OTC Exchange globally with <b>$100K+ turnover within 6 months</b> and is due for more in the upcoming days.</li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/otc-new/bankto-casestudy.webp"
                alt="Bankto Casestudy"
                width={780}
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo </a>
          </div>
        </div>
        </div>
      </section>
      </div>
    )
  }
}

export default CaseStudy