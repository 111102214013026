import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of our</span>
            OTC Crypto Exchange Platform
            </h3>
            <p className="pharagraph head">Coinsclone follows some standard procedures to create and deploy an OTC cryptocurrency exchange platform.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img className='lazyload' width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/otc/project-analysis.svg" alt="project analysis image1"  />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5>Requirement Gathering</h5>
            <p  className="pharagraph">In the initial stage, we would gather the requirements from clients and analyze them with the current crypto market. After the research, we will provide the right solution for starting an OTC exchange business.
            </p>
            <br />
            <h5 >Planning </h5>
            <p  className="pharagraph">Our experts will derive a rough plan for OTC cryptocurrency exchange development based on the business requirements of clients.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5>Designing</h5>
            <p  className="pharagraph">Each design that is implemented in the OTC exchange will be moved to the next stage after the confirmation from both the client-side and the designer team.
            </p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">In this stage, as a leading OTC exchange development services, we would develop and customize the entire crypto trading platform as per the client’s needs.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img className='lazyload' width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/otc/designing-development.svg" alt="Designing and development image1" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order2" >
            <img className='lazyload' width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/otc/testing-deployment.svg" alt="Testing and deployment image1" />
          </div>
          <div className="col-lg-6 col-md-12 padl60">
            <h5 >Testing</h5>
            <p  className="pharagraph">After the development stage, our testing team will check the quality of the OTC crypto exchange by conducting various tests. If any functionality doesn’t work properly, then our team will rectify the issue immediately.
            </p>
            <br />
            <h5>Deployment</h5>
            <p  className="pharagraph">Our development team will deploy the superfine OTC trading platform to the server as per the client’s request.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach