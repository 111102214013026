import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <div className='pt-100'>
      <section className="whyshould mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why to start</span> An OTC crypto exchange?
              </h2>
              <p className="pharagraph head">Starting an OTC cryptocurrency exchange business in the crypto ecosystem will make you rich and earn an ample amount of profits in a short period. Here we share a few notable reasons to develop an OTC crypto trading platform.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img className='lazyload' width="707px" height="525px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/why-should-you-start-otc-crypto-exchange.png" alt="Why Should Start OTC Crypto Exchange image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >Crypto OTC exchange is one of the popular revenue-generating exchange concepts and most users prefer this kind of exchange because of the bulk trading option.</li>
                <li className="pharagraph" >OTC trading platform has a modular architecture and lots of scalabilities.
                </li>
                <li className="pharagraph" >The bulk cryptocurrency transactions are protected with the latest security modules.</li>
                <li className="pharagraph" >You can generate a lucrative amount of money by collecting trading fees when your users perform bulk crypto trades.</li>
                <li className="pharagraph" >Since there is no intervention, individuals can buy and sell famous cryptocurrencies like Bitcoin and Ethereum without any hurdles. This leads to a greater number of transactions, resulting in more revenue for your exchange.</li>
                <li className="pharagraph" >Your users can easily handle cryptocurrencies as it comes with secure multi-crypto wallet support.</li>
                <li className="pharagraph" >As an admin of the exchange, you can set the price of crypto assets as per your wish.</li>
                <li className="pharagraph" >You can set the user’s buying and selling limit on your OTC cryptocurrency exchange platform. </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default WhyShould