import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">OTC Crypto Exchange  Development Company</h1>
            <p className="sub-heading">OTC trading network of cryptocurrencies</p>
            <p className="sub-small">Hire a team of well-experienced and skilled blockchain developers from Coinsclone to develop a feature-packed OTC crypto exchange platform for your business.</p>
          </div>
          <div className="quick text-center d-none d-md-block">
            <img className='lazyload' width="925px" height="591px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/otc-crypto-exchange-development.png" alt="OTC Crypto Exchange Development Company Image1" />
          </div>
          <p className="pharagraph">Grab a free demo of our OTC cryptocurrency exchange platform now!
          </p>
          <div className="cta-banner justify-content-md-center">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection